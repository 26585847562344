<template>
  <div class="product_rate_table">
    <div class="product_rates_table__example" v-for="(table, index) in rateTables" :key="`${productId}-${index}`">
      <h5 v-if="table.title">{{ table.title }}</h5>
      <b-table
          outlined
          thead-class="bg-success text-white"
          class="my-3"
          :items="table.items"
          :fields="table.fields"
          responsive
          stacked="sm"
      />
    </div>
    <p>The above rates are calculated over a {{ product.period.example }} month term.</p>
  </div>
</template>

<script>
import loanProducts from "@/data/loan-products";
import { calculateLoanPayment, calculateLoanTotal } from "@/data/calculator";
import { formatToCurrency, formatToPercentage } from "@/data/utils-number";

export default {
  name: "RatesTable",
  props: {
    productId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      formatToCurrency,
      formatToPercentage,
      calculateLoanPayment,
      calculateLoanTotal,
      loanProducts,
    };
  },
  computed: {
    product() {
      return this.loanProducts.find(product => product.id === this.productId);
    },
    rateTables() {
      return this.product.rates.map(rate => {
        let title =  this.product.rates.length > 1 ? `${this.formatToPercentage(rate.monthly)} per month (${this.formatToPercentage(rate.display)})` : null;
        return {
          title: title,
          fields: [
            { key: "loan", label: "Loan Amount" },
            { key: "repayments", label: `${(this.product.period.example - 1)} monthly repayments` },
            { key: "finalRepayment", label: "Final Repayment" },
            { key: "interest", label: "Total Interest" },
            { key: "totalRepayable", label: "Total Amount Repayable" },
          ],
          items: this.product.amount.examples.map(loan => {
            let monthlyRepayment = this.calculateLoanPayment(loan, rate.value, this.product.period.example);
            let totalRepayable = this.calculateLoanTotal(loan, rate.value, this.product.period.example);
            let finalRepayment = totalRepayable - (monthlyRepayment * (this.product.period.example - 1));
            let interest = totalRepayable - loan;
            return {
              loan: this.formatToCurrency(loan, 0),
              repayments: this.formatToCurrency(monthlyRepayment),
              finalRepayment: this.formatToCurrency(finalRepayment),
              interest: this.formatToCurrency(interest),
              totalRepayable: this.formatToCurrency(totalRepayable)
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>